var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.employeeStatusRequest.value === 'loading-getEmployees',"loading-text":"Đang tải dữ liệu","items":_vm.employees,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.phone,"### ### ####")))]):_c('span',[_vm._v("Không có")])]}},{key:"item.fund",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.fund))+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.branch && item.branch.name ? item.branch.name : "N/A")+" ")]}},{key:"item.role_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoleById(item.role_id) ? _vm.getRoleById(item.role_id).name : "Không có")+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }