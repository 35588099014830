<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="employeeStatusRequest.value === 'loading-getEmployees'"
    loading-text="Đang tải dữ liệu"
    :items="employees"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.phone`]="{ item }">
      <span v-if="item.phone">{{ item.phone | VMask("### ### ####") }}</span>
      <span v-else>Không có</span>
    </template>

    <template v-slot:[`item.fund`]="{ item }">
      {{ item.fund | formatCurrency }}
    </template>
    <template v-slot:[`item.address`]="{ item }">
      {{ item.branch && item.branch.name ? item.branch.name : "N/A" }}
    </template>

    <template v-slot:[`item.role_id`]="{ item }">
      {{
        getRoleById(item.role_id) ? getRoleById(item.role_id).name : "Không có"
      }}
    </template>
  </v-data-table>
</template>

<script>
import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  data() {
    return {
      headers: [
        {
          text: "Mã nhân viên",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên nhân viên",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "SĐT",
          align: "start",
          sortable: false,
          value: "phone"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "address"
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email"
        },
        {
          text: "Quỹ nhân viên",
          align: "start",
          sortable: false,
          value: "fund"
        },
        {
          text: "Vị trí",
          align: "start",
          sortable: false,
          value: "role_id"
        }
      ]
    };
  },
  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/employees"];
    },
    employeeStatusRequest() {
      return this.$store.getters["EMPLOYEE/statusRequest"];
    },
    roles() {
      return this.$store.getters["ROLE/roles"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  async created() {
    if (this.roles.length === 0) {
      await this.$store.dispatch("ROLE/getRoles");
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfEmployee(val) {
      const arr = this.employees.map(item => item.id);

      return arr.indexOf(val);
    },

    getRoleById(id) {
      return this.roles.find(item => item.id === id);
    },

    async viewDetail(item) {
      await this.$store.dispatch("EMPLOYEE/setEmployee", { ...item });

      this.$modal.show({
        name: "modal-employee"
      });

      this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
        id: item.id,
        type: BANK_ACCOUNT_OBJECT_TYPE.USER
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
