<template>
  <div>
    <!-- Start: Alert -->
    <v-card v-if="!employee.id" class="mb-5" flat>
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span>
          <strong>Số điện thoại</strong> được sử dụng để đăng nhập hệ thống. Mật
          khẩu đăng nhập sẽ được tạo tự động và gửi về số điện thoại của nhân
          viên trong ít phút ngay khi nhân viên được thêm mới.
        </span>
      </div>
    </v-card>
    <!-- End: Alert -->

    <!-- Start: Form -->
    <v-card color="px-5 pt-4 pb-2" flat>
      <v-form ref="form" class="d-flex">
        <!-- Start: Avatar -->
        <div class="pr-8">
          <div class="font-weight-bold mb-2">Ảnh đại diện</div>
          <tp-input-avatar
            :src="
              employee.avatar && employee.avatar.startsWith('media')
                ? employee.avatar
                : undefined
            "
            height="136px"
            width="136px"
            @change="employee.avatar = $event"
            @updateFormData="formDataAvatar = $event"
          ></tp-input-avatar>
        </div>
        <!-- End: Avatar -->
        <v-row class="my-0">
          <!-- Start: Name -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Tên nhân viên</div>
            <tp-text-field
              v-model="employee.name"
              placeholder="Nhập tên nhân viên"
              validate="required"
            ></tp-text-field>
          </v-col>
          <!-- End: Name -->
          <!-- Start: Email -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Email</div>
            <tp-text-field
              v-model="employee.email"
              placeholder="Nhập email"
              validate="required|email"
            ></tp-text-field>
          </v-col>
          <!-- End: Email -->
          <!-- Start: Phone -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Số điện thoại</div>
            <tp-text-field
              v-model="employee.phone"
              placeholder="Nhập số điện thoại"
              validate="required|tel"
            ></tp-text-field>
          </v-col>
          <!-- End: Phone -->
          <!-- Start: Role -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Vị trí</div>
            <tp-autocomplete
              v-model="employee.role_id"
              :items="roles"
              clearable
              item-text="name"
              item-value="id"
              placeholder="Chọn vị trí"
              validate="required"
            ></tp-autocomplete>
          </v-col>
          <!-- End: Role -->
          <!-- Start: Role -->
          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Chi nhánh</div>
            <tp-autocomplete
              v-model="employee.branch_id"
              :items="branches"
              clearable
              item-text="name"
              item-value="id"
              placeholder="Chọn chi nhánh"
              validate="required"
            ></tp-autocomplete>
          </v-col>

          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Quyền hạn</div>

            <div class="d-flex flex-wrap">
              <div v-for="(item, index) in staffRole" :key="index">
                <v-checkbox
                  v-model="employee.staff_roles"
                  :label="item.label"
                  :value="item.value"
                  class="mt-0 mr-4"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
          <!-- End: Role -->
        </v-row>
      </v-form>
    </v-card>
    <!-- End: Form -->

    <div class="mt-5">
      <v-btn
        v-if="employee.id"
        :loading="employeeStatusRequest.value === 'loading-updateEmployee'"
        color="primary"
        dark
        depressed
        @click="
          employeeStatusRequest.value === 'loading-updateEmployee'
            ? null
            : updateEmployee()
        "
      >
        Lưu
      </v-btn>
      <v-btn
        v-else
        :loading="employeeStatusRequest.value === 'loading-createEmployee'"
        color="primary"
        dark
        depressed
        @click="
          employeeStatusRequest.value === 'loading-createEmployee'
            ? null
            : createEmployee()
        "
      >
        Thêm
      </v-btn>
    </div>
  </div>
</template>

<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";

export default {
  data() {
    return {
      formDataAvatar: null,
      employeeTypes: [
        { id: 1, name: "Bán/Kho" },
        { id: 2, name: "Chỉ kho" },
        { id: 3, name: "Kho máy lỗi hỏng" },
        { id: 4, name: "Kho máy bảo hành" }
      ],
      staffRole: [
        { label: "Bán hàng", value: "SALES" },
        { label: "Kỹ thuật", value: "ENGINEERING" },
        { label: "Vận hành", value: "OPERATIONS" },
        { label: "Thu tiền mặt", value: "CASH" }
      ]
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employee() {
      return this.$store.getters["EMPLOYEE/employee"];
    },
    employeeStatusRequest() {
      return this.$store.getters["EMPLOYEE/statusRequest"];
    },
    roles() {
      return this.$store.getters["ROLE/roles"];
    },
    uploadedAvatar() {
      return this.$store.getters["EMPLOYEE/uploadedAvatar"];
    }
  },

  async created() {
    if (this.roles.length === 0) {
      await this.$store.dispatch("ROLE/getRoles");
    }
    if (this.branches.length === 0) {
      await this.$store.dispatch("BRANCH/getAllBranches");
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-employee"
      });
    },

    async createEmployee() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      const routeQuery = this.$route.query;

      // Check avatar
      if (this.employee.avatar && this.employee.avatar.startsWith("blob:")) {
        await this.uploadAvatar();
      }

      // Request create
      await this.$store.dispatch("EMPLOYEE/createEmployee", {
        data: this.employee,
        routeQuery: {
          filters: {
            role: routeQuery.role_id ? [parseInt(routeQuery.role_id)] : null,
            fromFund: routeQuery.fund_from
              ? parseFloat(routeQuery.fund_from)
              : null,
            toFund: routeQuery.fund_to ? parseFloat(routeQuery.fund_to) : null
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.employeeStatusRequest.value === "success-createEmployee") {
        showToastActionAlert(this, { message: "Thêm nhân viên thành công" });
      } else if (this.employeeStatusRequest.value === "error-createEmployee") {
        showModalAlertError(this, {
          title: "Lỗi thêm mới nhân viên",
          message: this.employeeStatusRequest.message
        });
      }
    },

    async deleteEmployee() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa nhân viên <strong>"${this.employee.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("EMPLOYEE/deleteEmployee", {
                data: this.employee.id,
                routeQuery: {
                  filters: {
                    role: routeQuery.role_id
                      ? [parseInt(routeQuery.role_id)]
                      : null,
                    fromFund: routeQuery.fund_from
                      ? parseFloat(routeQuery.fund_from)
                      : null,
                    toFund: routeQuery.fund_to
                      ? parseFloat(routeQuery.fund_to)
                      : null
                  },
                  search: routeQuery.search || null,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });

              if (
                this.employeeStatusRequest.value === "success-deleteEmployee"
              ) {
                this.$modal.hide({ name: "modal-employee" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async updateEmployee() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      const routeQuery = this.$route.query;

      // Check avatar change
      if (this.employee.avatar && this.employee.avatar.startsWith("blob:")) {
        await this.uploadAvatar();
      }

      // Request create
      await this.$store.dispatch("EMPLOYEE/updateEmployee", {
        data: this.employee,
        routeQuery: {
          filters: {
            role: routeQuery.role_id ? [parseInt(routeQuery.role_id)] : null,
            fromFund: routeQuery.fund_from
              ? parseFloat(routeQuery.fund_from)
              : null,
            toFund: routeQuery.fund_to ? parseFloat(routeQuery.fund_to) : null
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.employeeStatusRequest.value === "success-updateEmployee") {
        showToastActionAlert(this, {
          message: "Cập nhật nhân viên thành công"
        });
      } else if (this.employeeStatusRequest.value === "error-updateEmployee") {
        showModalAlertError(this, {
          title: "Lỗi cập nhật nhân viên",
          message: this.employeeStatusRequest.message
        });
      }
    },

    async uploadAvatar() {
      await this.$store.dispatch("EMPLOYEE/uploadAvatar", this.formDataAvatar);
      // Set avatar
      if (this.employeeStatusRequest.value === "success-uploadAvatar") {
        // Set uploaded avatar
        this.employee.avatar = this.uploadedAvatar;
      }
    }
  }
};
</script>
