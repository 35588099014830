<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :fund-filter="fundFilter"
        :role-filter="roleFilter"
        @updateRoleFilter="updateRoleFilter($event)"
        @updateFundFilter="updateFundFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal employee -->
    <modal-employee></modal-employee>
    <!-- End: Modal employee -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import ModalEmployee from "./components/modal/ModalEmployee/index";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    ModalEmployee,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      fundFilter: {
        from: null,
        to: null
      },
      itemPerPage: 50,
      roleFilter: [],
      searchKey: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getEmployees(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getEmployees(route.query);
  },
  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "partner-and-member_employees",
        query: {
          role_id: this.roleFilter ? this.roleFilter : undefined,
          fund_from:
            this.fundFilter.from !== null ? this.fundFilter.from : undefined,
          fund_to: this.fundFilter.to !== null ? this.fundFilter.to : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getEmployees(query) {
      if (query.page) {
        // Set debt filter
        this.fundFilter = {
          from:
            query.fund_from !== undefined ? parseFloat(query.fund_from) : null,
          to: query.fund_to !== undefined ? parseFloat(query.fund_to) : null
        };
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered role
        this.roleFilter = query.role_id ? parseInt(query.role_id) : null;
        // Set search key
        this.searchKey = query.search || null;

        // Get products
        await this.$store.dispatch("EMPLOYEE/getEmployees", {
          filter: {
            role: this.roleFilter ? [this.roleFilter] : null,
            fromFund: this.fundFilter.from,
            toFund: this.fundFilter.to
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateFundFilter(val) {
      this.fundFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateRoleFilter(val) {
      this.roleFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
