<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Tất cả nhân viên</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Vị trí</div>
        <v-autocomplete
          v-model="selectedRole"
          class="tp-filter-autocomplete"
          clearable
          :items="roles"
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          outlined
          single-line
          label="Outlined"
          placeholder="Chọn vị trí"
        ></v-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Quỹ nhân viên</div>
        <tp-input-price-filter
          :items="fundValueList"
          :value="fundFilter"
          @change="updateFundFilter($event)"
        ></tp-input-price-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    fundFilter: {
      type: Object
    },
    roleFilter: {
      type: Number
    }
  },
  data() {
    return {
      fundValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        {
          id: 4,
          text: "Trên 50 triệu",
          value: { from: 50000000.00001, to: null }
        },
        {
          id: 5,
          text: "Trên 100 triệu",
          value: { from: 100000000.00001, to: null }
        },
        {
          id: 6,
          text: "Trên 500 triệu",
          value: { from: 500000000.00001, to: null }
        },
        {
          id: 7,
          text: "Trên 1 tỷ",
          value: { from: 1000000000.00001, to: null }
        }
      ]
    };
  },
  computed: {
    roles() {
      return this.$store.getters["ROLE/roles"];
    },
    selectedRole: {
      get() {
        return this.roleFilter;
      },
      set(val) {
        this.$emit("updateRoleFilter", val);
      }
    }
  },
  async created() {
    if (this.roles.length === 0) {
      await this.$store.dispatch("ROLE/getRoles");
    }
  },
  methods: {
    updateFundFilter(val) {
      this.$emit("updateFundFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
